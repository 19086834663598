@font-face {
  font-family: Intel-Clear-Pro-Bold;
  src: url("../fonts/intel-clear-pro-bold.woff");
}
@font-face {
  font-family: Intel-Clear-Latin-Bold;
  src: url("../fonts/intel-clear-latin-bold.woff2");
}
@font-face {
  font-family: Intel-Clear-Latin-Lite;
  src: url("../fonts/intel-clear-latin-lite.woff2");
}

.font-intel-clear-pro-bold {
  font-family: "Intel-Clear-Pro-Bold";
  font-weight: bold;
}
.font-intel-clear-latin-bold {
  font-family: "Intel-Clear-Latin-Bold";
  font-weight: bold;
}
.font-intel-clear-latin-lite {
  font-family: "Intel-Clear-Latin-Lite";
  font-weight: 300;
}
