.social-media-section {
  padding-top: 30px;
  background-image: linear-gradient(to right, $theme-link-water, white, white);

  .section-title {
    margin-bottom: 40px;
  }
  .instagram-highlights-block {
    z-index: 1;
  }
  .instagram-highlights {
    @extend .d-flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 0 !important;
  }
  .instagram-card {
    width: 85px;
    height: 85px;
    background-color: white;
    background-size: cover;
    border-radius: 4px;

    &.view-on-instagram {
      @extend .d-flex, .font-intel-clear-latin-bold;
      align-items: center;
      justify-content: center;
      background-color: $theme-flame-pea;
      cursor: pointer;
      flex-direction: column;

      &:hover {
        background-color: $hover-theme-flame-pea;
      }

      img {
        width: 20px;
        height: 20px;
        margin-bottom: 6px;
      }

      p {
        margin-bottom: 0;
        max-width: 70px;
        font-size: 10px;
        line-height: 1.5;
      }
    }
  }
  .twitter-highlights {
    z-index: 1;
  }
  .twitter-card {
    border-radius: 8px;
    background-color: white;
    width: 100%;
    height: calc(100% - 30px);

    iframe {
      height: 265px !important;
    }
  }
}

@import "md";
@import "xl";
