// styling for footer
.footer {
  padding-top: 30px;
  overflow-x: hidden;

  // background-image: linear-gradient(to right, $theme-cello, $theme-stone);
  background-color: #ff5663;
  text-align: center;

  .row {
    @extend .d-flex;
  }

  .reach-us-at {
    @extend .font-intel-clear-latin-lite;
    font-size: 12px;
    line-height: 1;
    color: white;
    margin-bottom: 5px;
  }
  .contact-email {
    @extend .font-intel-clear-latin-bold;
    font-size: 18px;
    line-height: 1;
    color: white;
    margin-bottom: 15px;
    display: block;
  }

  .social-media-links {
    margin-bottom: 30px;
  }

  .social-media-link {
    img {
      width: 20px;
      height: 20px;
      opacity: 0.4;

      &:hover {
        opacity: 0.8;
      }
    }

    & + .social-media-link {
      margin-left: 20px;
    }
  }

  .footer-links {
    @extend .d-flex;
    flex-direction: column;
  }

  .footer-link {
    @extend .font-intel-clear-latin-bold;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-family: "Open Sans" !important;
    font-weight: 400;

    & + .footer-link {
      margin-top: 5px;
    }
  }

  .footer-logo {
    height: 50px;
  }

  .footer-side-links-container {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .footer-side-links {
    @extend .d-flex;
    flex-direction: column;
  }
  .footer-side-link {
    @extend .font-intel-clear-latin-bold;
    color: white;
    text-decoration: none;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 400;
    & + .footer-side-link {
      margin-top: 5px;
    }
  }

  .copyright-text {
    @extend .font-intel-clear-latin-lite;
    font-size: 12px;
    margin-top: 30px !important;
    font-weight: 600;
    margin-bottom: 0;
    color: $theme-white-50;
  }

  .footer-bottom {
    background-color: #fcb7b9;
  }
}

@import "md";
@import "xl";
