.header-wrapper {
  z-index: 400;
}
.header-container {
  z-index: 100;
  background-color: white;

  .row {
    @extend .d-flex;
  }

  .header-logos-container {
    padding: 10px 0;
    margin-left: auto;
    margin-right: auto;
  }

  .header-logo {
    height: 50px;
  }
}

.header {
  border-top: 1px solid #e9f0fa;
  border-bottom: 1px solid #e9f0fa;
  z-index: 100;
  background-color: white;

  .header-links-container {
    padding: 10px 20px;
    margin-left: auto;
    margin-right: auto;
  }

  a {
    color: #ec6266;
  }

  .header-link {
    @extend .font-intel-clear-latin-bold;
  }

  .nav-link {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
  }
  .dropdown {
    margin-top: 0;
  }

  .header-links-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .header-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .header-brand {
    @extend .font-intel-clear-pro-bold;
    font-size: 30px;
    margin-bottom: 15px;
  }

  .header-link {
    font-size: 16px;
    line-height: 1;
    margin: 0 10px;
    padding: 8px 10px;

    &.sign-up {
      @extend .btn-theme-primary;
      margin: 0 0 0 20px;

      &.danger {
        background-image: linear-gradient(120deg, #c70039 35%, #900 65%);
      }
    }
  }

  .profile-picture {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
  }
}

.cbse-logo {
  height: 40px !important;
}

.intel-logo {
  height: 25px !important;
}

.aisc-logo {
  height: 30px !important;
}

@import "md";
@import "xl";
